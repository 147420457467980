<template>
  <div>
    <div class="loading-wrapper" v-show="showLoading">
      <Loading></Loading>
    </div>
    <div class="login-wrapper" v-show="!showLoading">
      <img :src="imgUrl" alt="" width="100%" height="100%">
      <div class="login-title">
        <div class="login-title-wrapper">
          <img :src="imageUrl" alt="">
          <div class="login-title-wrapper-right">
            <div style="font-size: 18px;">北海市干部教育培训管理系统</div>
            <div style="float: left;">beihai city</div>
          </div>
        </div>
      </div>
      <el-divider class="login-divider"></el-divider>
      <div class="login">
        <div>
          <img :src="imgLeft" alt="" style="padding-left: 10px;">
        </div>
        <p class="title">登录</p>
        <img :src="imageBotton" alt="" style="margin-left: 185px; top: 75px;">
        <el-form
          :model="ruleForm"
          :label-position="labelPosition"
          status-icon
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item prop="name" label="用户名">
            <el-input v-model="ruleForm.name" auto-complete="off"  prefix-icon="el-icon-edit-outline" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="pass" label="密码">
            <el-input type="password" v-model="ruleForm.pass" prefix-icon="el-icon-document" auto-complete="off" placeholder="输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="login('ruleForm')" style="width:100%;">登录</el-button>
            <p class="register" @click="gotoRegist">没有账户？立即注册</p>
            <p class="studen"   @click="goStuden">管理员登录</p>
          </el-form-item>
        </el-form>
        <img :src="imgRight" alt="" style="bottom: 10px; le: 10px;">
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import Loading from '@/components/loading/Loading.vue'
  import { Login } from '@/api/user.js'
  import { setCookie } from '@/utils/base'
  // import store from '@/store/index'
  export default {
    name: 'Login',
    components: {
      Loading
    },
    data () {
      const checkName = (rule, value, callback) => {
        if (value.length !== 11) {
          callback(new Error('请输入正确的手机号码'))
        } else {
          callback()
        }
      }
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else {
          callback()
        }
      }
      return {
        showLoading: true,
        imgUrl: require('../../assets/img/欢迎.png'),
        imageUrl: require('../../assets/img/组 344.png'),
        imgLeft: require('../../assets/img/组 359.png'),
        imageBotton: require('../../assets/img/莲花 (1).png'),
        imgRight: require('../../assets/img/组 344.png'),
        ruleForm: {
          name: '',
          pass: ''
        },
        labelPosition: 'top',
        rules: {
          name: [{
            validator: checkName,
            trigger: 'change'
          }],
          pass: [{
            validator: validatePass,
            trigger: 'change'
          }]
        }
      }
    },
    mounted () {
      const bgImg = new Image()
      bgImg.src = this.imgUrl
      bgImg.onerror = () => {
        console.log('img onerror')
      }
      bgImg.onload = () => { // 图片加载成功后 去除loading
        this.showLoading = false
      }
    },
    methods: {
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      login (formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            const from = {
              username: this.ruleForm.name,
              password: this.ruleForm.pass,
              type: 0
            }
            Login(from).then(resp => {
              this.saveUser(this.ruleForm.name)
              if (resp.data.code === '200') {
                this.$notify({
                  title: '成功',
                  message: '恭喜，登录成功。',
                  type: 'success'
                })
                setCookie('isLogin', true)
                setCookie('expireTime', new Date().getTime() + 160 * 60 * 1000) // 设置cookie过期时间30*60*1000
                setTimeout(() => {
                  this.$router.push({
                    path: '/home'
                  })
                }, 500)
              } else if (resp.data.code === '405') {
                this.$notify({
                  title: '错误',
                  message: resp.data.msg,
                  type: 'error'
                })
              } else {
                this.$notify({
                  title: '账号或者密码错误！！',
                  message: '请重新输入！！',
                  type: 'error'
                })
              }
            })
          }
        })
      },
      gotoRegist () {
        this.$router.push({
          path: '/register'
        })
      },
      goStuden () {
        this.$router.push({
          path: '/Admin'
        })
      }
    }
  }
</script>
<style scoped>
  /deep/ .el-form--label-top .el-form-item__label {
    float: left;
    display: inline-block;
    text-align: left;
    padding: 0 0 10px;
  }
  .loading-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #aedff8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-title {
    position: absolute;
    color: #ffffff;
    z-index: 999;
    left: 120px;
    top: 20px;
  }
  .login-title-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-title-wrapper-right{
    padding-left: 60px;
    vertical-align:top;
  }
  .login-divider{
    position: absolute;
    z-index: 999;
    top: 50px;
  }

  .login-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .login-wrapper img {
    position: absolute;
    left: 0;
    z-index: 1;
  }

  .login {
    max-width: 340px;
    margin: 240px auto;
    background: #fff;
    padding: 20px 40px;
    border-radius: 10px;
    position: relative;
    z-index: 9;
  }

  .title {
    font-size: 26px;
    line-height: 50px;
    font-weight: bold;
    margin: 10px;
    text-align: center;
  }

  #login-form > input {
    width: 100%;
    height: 34px;
    display: block;
    margin-top: 26px;
    background: #fff;
    color: #333;
    border: 1px solid #7da9c7;
    outline: none;
    text-indent: 20px;
    font-size: 14px;
  }

  #login-form > button {
    width: 100%;
    height: 34px;
    display: block;
    margin-top: 26px;
    background: #1ab2ff;
    color: #fff;
    border: 1px solid #1ab2ff;
    outline: none;
    border-radius: 100px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }

  .register {
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    text-indent: 8px;
    color: #1ab2ff;
    cursor: pointer;
    display: inline-block;
    position: relative;
    left: -75px;
  }

  .register:hover {
    color: #2c2fd6;
  }

  @media (max-width: 768px) {
    .login {
      max-width: 260px;
      margin: 60px auto;
      background: #fff;
      padding: 20px 20px;
      border-radius: 10px;
      position: relative;
      z-index: 9;
    }
  }
  .studen{
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    text-indent: 8px;
    color: #1ab2ff;
    cursor: pointer;
    display: inline-block;
    position: relative;
    left: 60px;
  }
</style>
